import React, { useEffect } from "react";
import LandscapeProgramLayout from "../LandscapeProgramLayout";
import cn from "../../../utils/cn";
import { useAuthContext } from "../../../context/auth";
import BottomNav from "../BottomNav";
import { TimerRenderer, useTimer } from "react-use-precision-timer";

const MINI_CHARACTERS = [
  "/characters/1_mini.png",
  "/characters/2_mini.png",
  "/characters/3_mini.png",
  "/characters/4_mini.png",
];

const COLORS = ["#FF8563", "#FADE5A", "#4BE0DB", "#CAAAFF"];

const ROWS = {
  "300m": 300,
  "600m": 600,
  "1200m": 1200,
};

function interpolate(
  value: number,
  minFrom: number,
  maxFrom: number,
  minTo: number,
  maxTo: number
): number {
  if (value > maxFrom) return maxTo;
  return ((value - minFrom) * (maxTo - minTo)) / (maxFrom - minFrom) + minTo;
}

function Progress({
  active,
  progress,
  number,
}: {
  number: number;
  active: boolean;
  progress: number;
}) {
  return (
    <div
      className="w-[1600px] h-[100px] rounded-[100px] flex items-center justify-center "
      style={{
        border: "4px solid transparent",
        backgroundImage: "linear-gradient(135deg, #BFFF00 0%, #00D7B0 100%)",
        backgroundOrigin: "border-box",
        // backgroundClip: "content-box, border-box",
      }}
    >
      <div className="w-full h-full bg-bg rounded-[100px] flex items-center ">
        <div
          className="w-[984px] h-[84px] rounded-[84px] flex justify-end mx-2"
          style={{
            backgroundImage: `linear-gradient(270deg, ${
              COLORS[number - 1]
            } 0%, rgba(44, 218, 0, 0.00) 100%)`,
            transition: "all 0.5s ease",
            width: `${interpolate(progress, 0, 100, 0, 92)}%`,
          }}
        ></div>
        <img
          src={MINI_CHARACTERS[number - 1]}
          alt="character"
          className={cn("h-[85px]", !active && "mix-blend-luminosity")}
        />
      </div>
    </div>
  );
}

function LandscapeRow() {
  const { rowProgressData, rowConnected, did, row, sendRowConnect } =
    useAuthContext();
  useEffect(() => {
    sendRowConnect();
  }, []);

  const timer = useTimer({
    fireOnStart: false,
    startImmediately: false,
  });

  useEffect(() => {
    if (
      (
        Object.keys(rowProgressData) as Array<keyof typeof rowProgressData>
      ).some((key) => rowProgressData[key] > 0) &&
      !timer.isStarted()
    ) {
      timer.start();
    }
    if (
      (
        Object.keys(rowProgressData) as Array<keyof typeof rowProgressData>
      ).every(
        (key) => rowProgressData[key] >= ROWS[row as "300m" | "600m" | "1200m"]
      )
    ) {
      timer.pause();
    }
  }, [rowProgressData, did, timer, row]);

  if (row === undefined) {
    return <div>Loading...</div>;
  }

  return (
    <LandscapeProgramLayout step={5}>
      <div className="h-full w-full flex relative">
        <div className="p-[60px] flex flex-col gap-[80px] items-center w-full">
          <div className="flex items-center justify-between w-full">
            <h1 className="gradient-text text-[70px] font-bold">
              TEAM WORK OUT
            </h1>
            <div className="flex items-center gap-7">
              <TimerRenderer
                timer={timer}
                render={(t) => (
                  <p className="text-[80px] font-extrabold text-white leading-[100px]">
                    {String(
                      Math.floor(t.getElapsedRunningTime() / 1000 / 60)
                    ).padStart(2, "0")}
                    :
                    {String(
                      Math.floor((t.getElapsedRunningTime() / 1000) % 60)
                    ).padStart(2, "0")}
                  </p>
                )}
                renderRate={500} // In milliseconds
              />

              <p className="text-[48px] font-bold text-white">{row}</p>
              <p className="text-[48px] text-white font-bold">로밍</p>
            </div>
          </div>
          <div className="flex flex-col gap-[50px]">
            <Progress
              number={1}
              active={rowConnected.L1}
              progress={(rowProgressData.L1 * 100) / ROWS[row]}
            />
            <Progress
              number={2}
              active={rowConnected.L6}
              progress={(rowProgressData.L6 * 100) / ROWS[row]}
            />
            <Progress
              number={3}
              active={rowConnected.R1}
              progress={(rowProgressData.R1 * 100) / ROWS[row]}
            />
            <Progress
              number={4}
              active={rowConnected.R6}
              progress={(rowProgressData.R6 * 100) / ROWS[row]}
            />
          </div>
        </div>
      </div>
      <BottomNav />
    </LandscapeProgramLayout>
  );
}

export default LandscapeRow;
