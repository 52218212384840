import React, { useEffect, useState } from "react";
import {
  ComputerIcon2,
  ComputerIcon3,
  LeftChevron,
  LogoutIcon,
  RightChevron,
  RowingIcon,
  RowingIcon2,
} from "../Icons";
import { useAuthContext } from "../../context/auth";
import { getMonthlyPrograms, getProgram } from "../../client-api/program";
import { BaseProgram, CircuitProgram, WeightProgram } from "../../types";
import dayjs from "dayjs";
import Modal from "../Modal";
import cn from "../../utils/cn";
import { addRowing, getRowing } from "../../client-api/rowing";

interface Program extends BaseProgram {
  program: WeightProgram | CircuitProgram;
}
function Admin() {
  const { logout, startProgram } = useAuthContext();
  const [selected, setSelected] = useState<"program" | "row">("program");

  const [month, setMonth] = useState(new Date().toISOString().slice(0, 7));
  const [data, setData] = useState<Program[]>([]);
  const [row, setRow] = useState<"300m" | "600m" | "1200m">();
  const [selectedProgram, setSelectedProgram] = useState<Program | null>(null);
  const [program, setProgram] = useState<WeightProgram | CircuitProgram | null>(
    null
  );

  const [showVideo, setShowVideo] = useState(false);
  const [video, setVideo] = useState<string | null>(null);
  const [pins, setPins] = useState<string[]>([]);
  const [edit, setEdit] = useState(false);

  const getPins = () => {
    getRowing().then((data) => {
      setPins(data.rowingMachineIds);
    });
  };

  useEffect(() => {
    getPins();
  }, []);

  useEffect(() => {
    if (selectedProgram) {
      getProgram(String(selectedProgram.id)).then((data) => {
        console.log(data);
        setProgram(data as WeightProgram | CircuitProgram);
      });
    }
  }, [selectedProgram]);

  const getData = () => {
    const year = month.split("-")[0];
    const montha = month.split("-")[1];
    if (!year || !montha) return;
    getMonthlyPrograms(Number(year), Number(montha)).then((data) => {
      setData(
        data
          .map((item) => ({
            ...item,
            program: JSON.parse(item.programJson) as
              | WeightProgram
              | CircuitProgram,
          }))
          .sort((a, b) => dayjs(b.useDate).diff(dayjs(a.useDate)))
      );
    });
  };
  useEffect(() => {
    getData();
  }, []);

  const warnUpTime = (program?.warmUpMovements.length ?? 0) * 40;
  const firstTime =
    ((program as WeightProgram)?.firstHalfMovements?.length ?? 0) * 40;
  const secondTime =
    ((program as WeightProgram)?.secondHalfMovements?.length ?? 0) * 40;
  const WeightbreakTime = 3 * 60;
  const CircuitbreakTime = 2 * 60;
  const aTime = 900;
  const bTime = 900;
  const teamTime = 60 * 6;
  const coolTime = (program?.coolDownMovements.length ?? 0) * 40;

  return (
    <div className="h-full">
      <div className="flex h-full">
        <div className="flex flex-col items-stretch w-[16%] h-full">
          <div className="items-stretch self-stretch bg-black flex w-full grow flex-col mx-auto pl-4 lg:pl-10">
            <div className="justify-center flex flex-col pr-16 py-6 border-b-slate-600 border-b border-solid items-start max-md:pr-5">
              <img
                loading="lazy"
                src="/logo.png"
                className="aspect-[2.06] object-contain object-center w-[140px] justify-center items-center overflow-hidden max-w-full"
                alt="logo"
              />
            </div>
            <div className="text-slate-400 text-lg mt-10">프로그램 관리</div>
            <button
              className="items-stretch bg-white flex justify-between gap-2.5 mt-6 px-3 py-4 rounded-l max-md:pr-5"
              onClick={() => setSelected("program")}
            >
              <ComputerIcon3 />
              <div className="text-gray-950 text-xl grow whitespace-nowrap">
                월간 프로그램
              </div>
            </button>
            <div className="text-slate-400 text-lg mt-10">로밍머신 관리</div>
            <button
              className="items-stretch bg-white flex justify-between gap-2.5 mt-6 px-3 py-4 rounded-l max-md:pr-5"
              onClick={() => setSelected("row")}
            >
              <RowingIcon />
              <div className="text-gray-950 text-xl grow whitespace-nowrap">
                로잉머신
              </div>
            </button>
            <div className="justify-end items-stretch flex flex-col grow pr-6 py-8 border-t-slate-600 border-t border-solid max-md:mt-10 max-md:pr-5">
              <div
                className="items-stretch bg-slate-600 flex justify-between gap-2.5 pl-6 pr-3 py-4 rounded-lg"
                onClick={() => {
                  logout();
                }}
              >
                <LogoutIcon />
                <div className="text-white text-lg font-bold self-center grow whitespace-nowrap my-auto">
                  로그아웃
                </div>
              </div>
            </div>
          </div>
        </div>
        {selected === "program" ? (
          <div className="flex flex-col items-stretch flex-1">
            <div className="items-stretch self-stretch flex grow flex-col w-full max-h-full">
              <div className="bg-[linear-gradient(135deg,#BFFF00_0%,#00D7B0_100%)] flex flex-col justify-center pr-16 border-b-slate-200 border-b border-solid items-start max-md:max-w-full max-md:pr-5">
                <div className="flex-col fill-black overflow-hidden relative flex aspect-[3.4482758620689653] w-[400px] max-w-full justify-center pl-6 pr-16 py-9 items-start max-md:px-5">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="400"
                    height="116"
                    viewBox="0 0 400 116"
                    fill="none"
                    className="absolute h-full w-full object-cover object-center inset-0"
                  >
                    <path d="M0 0H400L355.311 116H0V0Z" fill="black" />
                  </svg>
                  <div className="relative items-center flex gap-3">
                    <ComputerIcon2 />
                    <div className="text-white text-4xl font-bold self-stretch grow whitespace-nowrap">
                      월간 프로그램
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex-1 overflow-y-scroll">
                <div className="bg-slate-50 max-md:max-w-full max-h-full">
                  <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0 h-full">
                    <div className="flex flex-col items-stretch max-h-full overflow-y-scroll">
                      <div className="self-stretch bg-white flex grow flex-col items-stretch w-full border-x-slate-200 border-r border-solid border-l max-md:max-w-full max-md:mt-2.5">
                        <div className="justify-center bg-white flex flex-col pl-6 pr-16 py-6 items-start max-md:max-w-full max-md:px-5">
                          <div className="items-stretch flex gap-2">
                            <div className="text-black text-center text-xl font-bold grow whitespace-nowrap">
                              월간 프로그램
                            </div>
                            <div className="text-lime-400 text-center text-xl font-bold whitespace-nowrap">
                              {data.length}
                            </div>
                          </div>
                        </div>
                        <div className="items-stretch bg-white flex flex-col pb-6 px-6 border-b-slate-200 border-b border-solid max-md:max-w-full max-md:px-5">
                          <div className="items-stretch flex justify-between gap-3 max-md:max-w-full max-md:flex-wrap">
                            <input
                              placeholder="월"
                              type="month"
                              onChange={(e) => {
                                setMonth(e.target.value);
                              }}
                              value={month}
                              className="items-stretch rounded border bg-slate-50 flex justify-between gap-2.5 pl-3 pr-1.5 py-1.5 border-solid border-slate-400 max-md:max-w-full max-md:flex-wrap"
                            ></input>
                            <div
                              onClick={() => getData()}
                              className="text-black text-lg whitespace-nowrap items-stretch rounded bg-[linear-gradient(135deg,#BFFF00_0%,#00D7B0_100%)] aspect-[1.696969696969697] justify-center px-3 py-1.5"
                            >
                              검색
                            </div>
                          </div>
                        </div>
                        <div className="items-stretch bg-slate-50 flex gap-5 mt-11 px-6 py-4 border-b-slate-200 border-b border-solid max-md:max-w-full max-md:flex-wrap max-md:mt-10 max-md:px-5">
                          <div className="text-black text-lg whitespace-nowrap">
                            순서
                          </div>
                          <div className="text-black text-lg">사용날짜</div>
                          <div className="text-black text-lg">프로그램명</div>
                          <div className="text-black text-lg grow whitespace-nowrap">
                            종류
                          </div>
                        </div>
                        {data.map((item, index) => (
                          <button
                            className={cn(
                              "items-stretch flex gap-5 px-6 py-3 border-b-slate-200 border-b border-solid max-md:max-w-full max-md:flex-wrap max-md:px-5",
                              selectedProgram?.id === item.id
                                ? "bg-slate-100 font-bold"
                                : "bg-white"
                            )}
                            onClick={() => {
                              setSelectedProgram(item);
                            }}
                          >
                            <div className="text-black text-lg whitespace-nowrap">
                              {index + 1}
                            </div>
                            <div className="text-black text-lg">
                              {dayjs(item.useDate).format("YYYY.MM.DD")}
                            </div>
                            <div className="text-black text-lg">
                              {item.name}
                            </div>
                            <div className="text-black text-lg grow whitespace-nowrap">
                              {item.programType === "WEIGHT"
                                ? "웨이트"
                                : item.programType === "CIRCUIT"
                                ? "서킷"
                                : "부스터"}
                            </div>
                          </button>
                        ))}
                        <div className="justify-center items-center bg-slate-50 flex flex-col px-16 py-4 border-t-slate-200 border-t border-solid max-md:max-w-full max-md:px-5">
                          <div className="justify-between items-start flex gap-3 max-md:max-w-full max-md:flex-wrap">
                            {/* <LeftChevron /> */}
                            <div className="text-black text-center text-lg self-center my-auto">
                              1
                            </div>
                            {/* <RightChevron /> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    {selectedProgram && program && (
                      <div className="flex flex-col items-stretch flex-1 overflow-y-scroll max-h-full">
                        <div className="bg-white flex grow flex-col w-full border-l-slate-200 border-l">
                          <div className="justify-between items-center bg-white self-stretch flex gap-5 p-6 border-b-slate-200 border-b border-solid max-md:max-w-full max-md:flex-wrap max-md:px-5">
                            <div className="text-black text-center text-xl font-bold my-auto">
                              수업 프로그램
                            </div>
                            <div className="flex itmes-center gap-4">
                              {selectedProgram.program.isTeamWorkOutAvailable &&
                                selectedProgram.program.teamWorkOuts[0]
                                  ?.type === "ROWING_MACHINE" && (
                                  <select
                                    className="rounded border border-[#8D9CB6] bg-[#F8F8FC] gap-[10px] pl-3 pr-[6px] self-center h-[32px]"
                                    value={row}
                                    onChange={(e) => {
                                      setRow(
                                        e.target.value as
                                          | "300m"
                                          | "600m"
                                          | "1200m"
                                      );
                                    }}
                                  >
                                    <option selected disabled>
                                      선택안함
                                    </option>
                                    <option value="300m">300m</option>
                                    <option value="600m">600m</option>
                                    <option value="1200m">1200m</option>
                                  </select>
                                )}
                              <button
                                className="text-white text-center text-lg font-bold whitespace-nowrap justify-center items-stretch bg-[linear-gradient(135deg,#BFFF00_0%,#00D7B0_100%)] self-stretch px-5 py-5 rounded-lg"
                                onClick={() => {
                                  startProgram(selectedProgram.id, row);
                                }}
                              >
                                수업 시작
                              </button>
                            </div>
                          </div>
                          <div className="items-stretch flex w-[669px] max-w-full justify-between gap-5 ml-6 mt-6 pr-20 self-start max-md:flex-wrap max-md:pr-5">
                            <div className="items-stretch flex justify-between gap-4">
                              <div className="text-slate-600 text-lg grow whitespace-nowrap">
                                사용날짜
                              </div>
                              <div className="text-black text-lg font-bold grow whitespace-nowrap">
                                {dayjs(selectedProgram.useDate).format(
                                  "YYYY.MM.DD"
                                )}
                              </div>
                            </div>
                            <div className="items-stretch flex justify-between gap-4">
                              <div className="text-slate-600 text-lg">
                                프로그램명
                              </div>
                              <div className="text-black text-lg font-bold">
                                {selectedProgram.name}
                              </div>
                            </div>
                            <div className="items-stretch flex justify-between gap-4">
                              <div className="text-slate-600 text-lg">종류</div>
                              <div className="text-black text-lg font-bold">
                                {selectedProgram.programType === "WEIGHT"
                                  ? "웨이트"
                                  : selectedProgram.programType === "CIRCUIT"
                                  ? "써킷"
                                  : "부스터"}
                              </div>
                            </div>
                          </div>
                          <div className="justify-center bg-white self-stretch flex flex-col mt-9 pl-8 pr-16 py-6 items-start max-md:max-w-full max-md:px-5">
                            <div className="items-stretch flex gap-2">
                              <div className="text-black text-center text-xl font-bold grow whitespace-nowrap">
                                운동 소요 시간
                              </div>
                              <div className="text-lime-400 text-center text-xl font-bold whitespace-nowrap">
                                {selectedProgram.programType === "WEIGHT"
                                  ? `${
                                      Math.floor(
                                        warnUpTime +
                                          firstTime +
                                          secondTime +
                                          WeightbreakTime +
                                          teamTime +
                                          coolTime
                                      ) / 60
                                    }분 ${
                                      (warnUpTime +
                                        firstTime +
                                        secondTime +
                                        WeightbreakTime +
                                        teamTime +
                                        coolTime) %
                                        60 >
                                      0
                                        ? `${
                                            (warnUpTime +
                                              firstTime +
                                              secondTime +
                                              WeightbreakTime +
                                              teamTime +
                                              coolTime) %
                                            60
                                          }초`
                                        : ""
                                    }`
                                  : `${
                                      Math.floor(
                                        warnUpTime +
                                          aTime +
                                          bTime +
                                          WeightbreakTime +
                                          teamTime +
                                          coolTime
                                      ) / 60
                                    }분 ${
                                      (warnUpTime +
                                        aTime +
                                        bTime +
                                        CircuitbreakTime +
                                        teamTime +
                                        coolTime) %
                                        60 >
                                      0
                                        ? `${
                                            (warnUpTime +
                                              aTime +
                                              bTime +
                                              CircuitbreakTime +
                                              teamTime +
                                              coolTime) %
                                            60
                                          }초`
                                        : ""
                                    }`}
                              </div>
                            </div>
                          </div>
                          <div className="self-stretch mx-6 max-md:max-w-full max-md:mr-2.5">
                            <div className="flex">
                              <div className="flex flex-col items-stretch flex-1">
                                <div className="items-stretch flex grow flex-col max-md:max-w-full">
                                  <div className="items-stretch flex justify-between gap-0 max-md:max-w-full max-md:flex-wrap">
                                    <div className="text-black text-lg whitespace-nowrap items-stretch bg-slate-50 justify-center pl-6 py-3 border-b-slate-200 border-b border-solid w-[175px]">
                                      Warm up
                                    </div>
                                    <div className="text-black text-lg whitespace-nowrap items-stretch grow justify-center px-6 py-3 border-b-slate-200 border-b border-solid max-md:px-5">
                                      {warnUpTime / 60}분 {warnUpTime % 60}초
                                    </div>
                                  </div>
                                  <div className="items-stretch flex justify-between gap-0 max-md:max-w-full max-md:flex-wrap">
                                    <div className="text-black text-lg whitespace-nowrap items-stretch bg-slate-50 justify-center pl-6 py-3 border-b-slate-200 border-b border-solid w-[175px]">
                                      {selectedProgram.programType === "WEIGHT"
                                        ? "전반전"
                                        : "A구역"}
                                    </div>
                                    <div className="text-black text-lg whitespace-nowrap items-stretch grow justify-center px-6 py-3 border-b-slate-200 border-b border-solid max-md:px-5">
                                      {selectedProgram.programType === "WEIGHT"
                                        ? `${firstTime / 60}분 ${
                                            firstTime % 60
                                          }초`
                                        : `${aTime / 60}분 ${aTime % 60}초`}
                                    </div>
                                  </div>
                                  <div className="items-stretch flex justify-between gap-0 max-md:max-w-full max-md:flex-wrap">
                                    <div className="text-black text-lg whitespace-nowrap items-stretch bg-slate-50 justify-center pl-6 py-3 border-b-slate-200 border-b border-solid w-[175px]">
                                      휴식
                                    </div>
                                    <div className="text-black text-lg whitespace-nowrap items-stretch grow justify-center px-6 py-3 border-b-slate-200 border-b border-solid max-md:px-5">
                                      {selectedProgram.programType === "WEIGHT"
                                        ? `${WeightbreakTime / 60}분 ${
                                            WeightbreakTime % 60
                                          }초`
                                        : `${CircuitbreakTime / 60}분 ${
                                            CircuitbreakTime % 60
                                          }초`}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="flex flex-col items-stretch flex-1">
                                <div className="items-stretch flex grow flex-col max-md:max-w-full">
                                  <div className="items-stretch flex justify-between gap-0 max-md:max-w-full max-md:flex-wrap">
                                    <div className="text-black text-lg whitespace-nowrap items-stretch bg-slate-50 justify-center pl-6 py-3 border-b-slate-200 border-b border-solid w-[175px]">
                                      {selectedProgram.programType === "WEIGHT"
                                        ? "후반전"
                                        : "B구역"}
                                    </div>
                                    <div className="text-black text-lg whitespace-nowrap items-stretch grow justify-center px-6 py-3 border-b-slate-200 border-b border-solid max-md:px-5">
                                      {selectedProgram.programType === "WEIGHT"
                                        ? `${secondTime / 60}분 ${
                                            secondTime % 60
                                          }초`
                                        : `${bTime / 60}분 ${bTime % 60}초`}
                                    </div>
                                  </div>
                                  <div className="items-stretch flex justify-between gap-0 max-md:max-w-full max-md:flex-wrap">
                                    <div className="text-black text-lg whitespace-nowrap items-stretch bg-slate-50 justify-center pl-6 py-3 border-b-slate-200 border-b border-solid w-[175px]">
                                      Team work out
                                    </div>
                                    <div className="text-black text-lg whitespace-nowrap items-stretch grow justify-center px-6 py-3 border-b-slate-200 border-b border-solid max-md:px-5">
                                      {teamTime / 60}분 {teamTime % 60}초
                                    </div>
                                  </div>
                                  <div className="items-stretch flex justify-between gap-0 max-md:max-w-full max-md:flex-wrap">
                                    <div className="text-black text-lg whitespace-nowrap items-stretch bg-slate-50 justify-center pl-6 py-3 border-b-slate-200 border-b border-solid w-[175px]">
                                      Cool down
                                    </div>
                                    <div className="text-black text-lg whitespace-nowrap items-stretch grow justify-center px-6 py-3 border-b-slate-200 border-b border-solid max-md:px-5">
                                      {coolTime / 60}분 {coolTime % 60}초
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="text-black text-xl font-bold whitespace-nowrap justify-center bg-white w-[717px] max-w-full mt-6 pl-6 pr-16 py-6 self-start items-start max-md:px-5">
                            운동 소요 시간
                          </div>
                          <div className="items-stretch self-stretch flex justify-between gap-0 mx-6 max-md:max-w-full max-md:flex-wrap max-md:mr-2.5">
                            <div className="text-black text-lg font-bold whitespace-nowrap bg-slate-50 grow justify-center pl-6 pr-16 py-3 border-b-slate-200 border-b border-solid items-start max-md:max-w-full max-md:px-5">
                              Warm up - {warnUpTime / 60}분 {warnUpTime % 60}초
                            </div>
                            <div className="text-black text-lg font-bold whitespace-nowrap justify-center items-center bg-slate-50 px-16 py-3 border-b-slate-200 border-b border-solid max-md:px-5">
                              위치
                            </div>
                          </div>
                          {program.warmUpMovements.map((item) => (
                            <div className="items-stretch self-stretch flex justify-between gap-0 mx-6 max-md:max-w-full max-md:flex-wrap max-md:mr-2.5">
                              <div className="flex-1 justify-between items-stretch flex gap-5 px-6 py-3 border-b-slate-200 border-b border-solid max-md:max-w-full max-md:flex-wrap max-md:px-5">
                                <div className="items-stretch flex justify-between gap-4">
                                  <img
                                    loading="lazy"
                                    className="aspect-square object-contain object-center w-[60px] justify-center items-center overflow-hidden shrink-0 max-w-full"
                                    src={item.image}
                                    alt="동작 이미지"
                                  />
                                  <div className="text-black text-lg self-center my-auto">
                                    {item.name}
                                  </div>
                                </div>
                                <img
                                  loading="lazy"
                                  className="aspect-square object-contain object-center w-6 justify-center items-center overflow-hidden self-center shrink-0 max-w-full my-auto"
                                  src="/right-chevron.png"
                                  alt="동작 이미지"
                                  onClick={() => {
                                    setShowVideo(true);
                                    setVideo(item.video);
                                  }}
                                />
                              </div>
                              <div className="text-black text-lg whitespace-nowrap justify-center items-center px-16 py-8 border-b-slate-200 border-b border-solid max-md:px-5">
                                가로
                              </div>
                            </div>
                          ))}
                          {selectedProgram.programType === "CIRCUIT" ||
                          selectedProgram.programType === "BOOSTER" ? (
                            <>
                              <div className="items-stretch self-stretch flex justify-between gap-0 mx-6 max-md:max-w-full max-md:flex-wrap max-md:mr-2.5">
                                <div className="text-black text-lg font-bold whitespace-nowrap bg-slate-50 grow justify-center pl-6 pr-16 py-3 border-b-slate-200 border-b border-solid items-start max-md:max-w-full max-md:px-5">
                                  A구역 - {aTime / 60}분 {aTime % 60}초
                                </div>
                                <div className="text-black text-lg font-bold whitespace-nowrap justify-center items-center bg-slate-50 px-16 py-3 border-b-slate-200 border-b border-solid max-md:px-5">
                                  위치
                                </div>
                              </div>
                              {(program as CircuitProgram).areaAMovements?.map(
                                (item, index) => (
                                  <div className="items-stretch self-stretch flex justify-between gap-0 mx-6 max-md:max-w-full max-md:flex-wrap max-md:mr-2.5">
                                    <div className="flex-1 justify-between items-stretch flex gap-5 px-6 py-3 border-b-slate-200 border-b border-solid max-md:max-w-full max-md:flex-wrap max-md:px-5">
                                      <div className="items-stretch flex justify-between gap-4">
                                        <img
                                          loading="lazy"
                                          className="aspect-square object-contain object-center w-[60px] justify-center items-center overflow-hidden shrink-0 max-w-full"
                                          src={item.image}
                                          alt="동작 이미지"
                                        />
                                        <div className="text-black text-lg self-center my-auto">
                                          {item.name}
                                        </div>
                                      </div>
                                      <img
                                        loading="lazy"
                                        className="aspect-square object-contain object-center w-6 justify-center items-center overflow-hidden self-center shrink-0 max-w-full my-auto"
                                        src="/right-chevron.png"
                                        alt="동작 이미지"
                                        onClick={() => {
                                          setShowVideo(true);
                                          setVideo(item.video);
                                        }}
                                      />
                                    </div>
                                    <div className="text-black text-lg whitespace-nowrap justify-center items-center px-16 py-8 border-b-slate-200 border-b border-solid max-md:px-5">
                                      {`L${index + 1}`}
                                    </div>
                                  </div>
                                )
                              )}
                              <div className="items-stretch self-stretch flex justify-between gap-0 mx-6 max-md:max-w-full max-md:flex-wrap max-md:mr-2.5">
                                <div className="text-black text-lg font-bold whitespace-nowrap bg-slate-50 grow justify-center pl-6 pr-16 py-3 border-b-slate-200 border-b border-solid items-start max-md:max-w-full max-md:px-5">
                                  B구역 - {aTime / 60}분 {aTime % 60}초
                                </div>
                                <div className="text-black text-lg font-bold whitespace-nowrap justify-center items-center bg-slate-50 px-16 py-3 border-b-slate-200 border-b border-solid max-md:px-5">
                                  위치
                                </div>
                              </div>
                              {(program as CircuitProgram).areaBMovements?.map(
                                (item, index) => (
                                  <div className="items-stretch self-stretch flex justify-between gap-0 mx-6 max-md:max-w-full max-md:flex-wrap max-md:mr-2.5">
                                    <div className="flex-1 justify-between items-stretch flex gap-5 px-6 py-3 border-b-slate-200 border-b border-solid max-md:max-w-full max-md:flex-wrap max-md:px-5">
                                      <div className="items-stretch flex justify-between gap-4">
                                        <img
                                          loading="lazy"
                                          className="aspect-square object-contain object-center w-[60px] justify-center items-center overflow-hidden shrink-0 max-w-full"
                                          src={item.image}
                                          alt="동작 이미지"
                                        />
                                        <div className="text-black text-lg self-center my-auto">
                                          {item.name}
                                        </div>
                                      </div>
                                      <img
                                        loading="lazy"
                                        className="aspect-square object-contain object-center w-6 justify-center items-center overflow-hidden self-center shrink-0 max-w-full my-auto"
                                        src="/right-chevron.png"
                                        alt="동작 이미지"
                                        onClick={() => {
                                          setShowVideo(true);
                                          setVideo(item.video);
                                        }}
                                      />
                                    </div>
                                    <div className="text-black text-lg whitespace-nowrap justify-center items-center px-16 py-8 border-b-slate-200 border-b border-solid max-md:px-5">
                                      {`R${index + 1}`}
                                    </div>
                                  </div>
                                )
                              )}
                            </>
                          ) : (
                            <>
                              <div className="items-stretch self-stretch flex justify-between gap-0 mx-6 max-md:max-w-full max-md:flex-wrap max-md:mr-2.5">
                                <div className="text-black text-lg font-bold whitespace-nowrap bg-slate-50 grow justify-center pl-6 pr-16 py-3 border-b-slate-200 border-b border-solid items-start max-md:max-w-full max-md:px-5">
                                  전반전 - {firstTime / 60}분 {firstTime % 60}초
                                </div>
                                <div className="text-black text-lg font-bold whitespace-nowrap justify-center items-center bg-slate-50 px-16 py-3 border-b-slate-200 border-b border-solid max-md:px-5">
                                  위치
                                </div>
                              </div>
                              {(
                                program as WeightProgram
                              ).firstHalfMovements?.map((item) => (
                                <div className="items-stretch self-stretch flex justify-between gap-0 mx-6 max-md:max-w-full max-md:flex-wrap max-md:mr-2.5">
                                  <div className="flex-1 justify-between items-stretch flex gap-5 px-6 py-3 border-b-slate-200 border-b border-solid max-md:max-w-full max-md:flex-wrap max-md:px-5">
                                    <div className="items-stretch flex justify-between gap-4">
                                      <img
                                        loading="lazy"
                                        className="aspect-square object-contain object-center w-[60px] justify-center items-center overflow-hidden shrink-0 max-w-full"
                                        src={item.movement.image}
                                        alt="동작 이미지"
                                      />
                                      <div className="text-black text-lg self-center my-auto">
                                        {item.movement.name}
                                      </div>
                                    </div>
                                    <img
                                      loading="lazy"
                                      className="aspect-square object-contain object-center w-6 justify-center items-center overflow-hidden self-center shrink-0 max-w-full my-auto"
                                      src="/right-chevron.png"
                                      alt="동작 이미지"
                                      onClick={() => {
                                        setShowVideo(true);
                                        setVideo(item.movement.video);
                                      }}
                                    />
                                  </div>
                                  <div className="text-black text-lg whitespace-nowrap justify-center items-center px-16 py-8 border-b-slate-200 border-b border-solid max-md:px-5">
                                    {item.did}
                                  </div>
                                </div>
                              ))}
                              <div className="items-stretch self-stretch flex justify-between gap-0 mx-6 max-md:max-w-full max-md:flex-wrap max-md:mr-2.5">
                                <div className="text-black text-lg font-bold whitespace-nowrap bg-slate-50 grow justify-center pl-6 pr-16 py-3 border-b-slate-200 border-b border-solid items-start max-md:max-w-full max-md:px-5">
                                  후반전 - {secondTime / 60}분 {secondTime % 60}
                                  초
                                </div>
                                <div className="text-black text-lg font-bold whitespace-nowrap justify-center items-center bg-slate-50 px-16 py-3 border-b-slate-200 border-b border-solid max-md:px-5">
                                  위치
                                </div>
                              </div>
                              {(
                                program as WeightProgram
                              ).secondHalfMovements?.map((item) => (
                                <div className="items-stretch self-stretch flex justify-between gap-0 mx-6 max-md:max-w-full max-md:flex-wrap max-md:mr-2.5">
                                  <div className="flex-1 justify-between items-stretch flex gap-5 px-6 py-3 border-b-slate-200 border-b border-solid max-md:max-w-full max-md:flex-wrap max-md:px-5">
                                    <div className="items-stretch flex justify-between gap-4">
                                      <img
                                        loading="lazy"
                                        className="aspect-square object-contain object-center w-[60px] justify-center items-center overflow-hidden shrink-0 max-w-full"
                                        src={item.movement.image}
                                        alt="동작 이미지"
                                      />
                                      <div className="text-black text-lg self-center my-auto">
                                        {item.movement.name}
                                      </div>
                                    </div>
                                    <img
                                      loading="lazy"
                                      className="aspect-square object-contain object-center w-6 justify-center items-center overflow-hidden self-center shrink-0 max-w-full my-auto"
                                      src="/right-chevron.png"
                                      alt="동작 이미지"
                                      onClick={() => {
                                        setShowVideo(true);
                                        setVideo(item.movement.video);
                                      }}
                                    />
                                  </div>
                                  <div className="text-black text-lg whitespace-nowrap justify-center items-center px-16 py-8 border-b-slate-200 border-b border-solid max-md:px-5">
                                    {item.did}
                                  </div>
                                </div>
                              ))}
                            </>
                          )}
                          <div className="items-stretch self-stretch flex justify-between gap-0 mx-6 max-md:max-w-full max-md:flex-wrap max-md:mr-2.5">
                            <div className="text-black text-lg font-bold whitespace-nowrap bg-slate-50 grow justify-center pl-6 pr-16 py-3 border-b-slate-200 border-b border-solid items-start max-md:max-w-full max-md:px-5">
                              {selectedProgram.program.isTeamWorkOutAvailable &&
                              selectedProgram.program.teamWorkOuts[0]?.type ===
                                "ROWING_MACHINE"
                                ? "Rowing Machine"
                                : "Team Work Out"}
                            </div>
                          </div>
                          {program.teamWorkOuts.map((item) => (
                            <div className="items-stretch self-stretch flex justify-between gap-0 mx-6 max-md:max-w-full max-md:flex-wrap max-md:mr-2.5">
                              <div className="flex-1 justify-between items-stretch flex gap-5 px-6 py-3 border-b-slate-200 border-b border-solid max-md:max-w-full max-md:flex-wrap max-md:px-5">
                                <div className="items-stretch flex justify-between gap-4">
                                  <div className="text-black text-lg self-center my-auto">
                                    {item.context}
                                  </div>
                                </div>
                                {item.url && (
                                  <a href={item.url}>
                                    <img
                                      loading="lazy"
                                      className="aspect-square object-contain object-center w-6 justify-center items-center overflow-hidden self-center shrink-0 max-w-full my-auto"
                                      src="/right-chevron.png"
                                      alt="동작 이미지"
                                    />
                                  </a>
                                )}
                              </div>
                            </div>
                          ))}
                          <div className="items-stretch self-stretch flex justify-between gap-0 mx-6 max-md:max-w-full max-md:flex-wrap max-md:mr-2.5">
                            <div className="text-black text-lg font-bold whitespace-nowrap bg-slate-50 grow justify-center pl-6 pr-16 py-3 border-b-slate-200 border-b border-solid items-start max-md:max-w-full max-md:px-5">
                              쿨다운 - {coolTime / 60}분 {coolTime % 60}초
                            </div>
                            <div className="text-black text-lg font-bold whitespace-nowrap justify-center items-center bg-slate-50 px-16 py-3 border-b-slate-200 border-b border-solid max-md:px-5">
                              위치
                            </div>
                          </div>
                          {program.coolDownMovements.map((item) => (
                            <div className="items-stretch self-stretch flex justify-between gap-0 mx-6 max-md:max-w-full max-md:flex-wrap max-md:mr-2.5">
                              <div className="flex-1 justify-between items-stretch flex gap-5 px-6 py-3 border-b-slate-200 border-b border-solid max-md:max-w-full max-md:flex-wrap max-md:px-5">
                                <div className="items-stretch flex justify-between gap-4">
                                  <img
                                    loading="lazy"
                                    className="aspect-square object-contain object-center w-[60px] justify-center items-center overflow-hidden shrink-0 max-w-full"
                                    src={item.movement.image}
                                    alt="동작 이미지"
                                  />
                                  <div className="text-black text-lg self-center my-auto">
                                    {item.movement.name}
                                  </div>
                                </div>
                                <img
                                  loading="lazy"
                                  className="aspect-square object-contain object-center w-6 justify-center items-center overflow-hidden self-center shrink-0 max-w-full my-auto"
                                  src="/right-chevron.png"
                                  alt="동작 이미지"
                                  onClick={() => {
                                    setShowVideo(true);
                                    setVideo(item.movement.video);
                                  }}
                                />
                              </div>
                              <div className="text-black text-lg whitespace-nowrap justify-center items-center px-16 py-8 border-b-slate-200 border-b border-solid max-md:px-5">
                                가로
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex flex-col items-stretch flex-1">
            <div className="items-stretch self-stretch flex grow flex-col w-full max-h-full">
              <div className="bg-[linear-gradient(135deg,#BFFF00_0%,#00D7B0_100%)] flex flex-col justify-center pr-16 border-b-slate-200 border-b border-solid items-start max-md:max-w-full max-md:pr-5">
                <div className="flex-col fill-black overflow-hidden relative flex aspect-[3.4482758620689653] w-[400px] max-w-full justify-center pl-6 pr-16 py-9 items-start max-md:px-5">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="400"
                    height="116"
                    viewBox="0 0 400 116"
                    fill="none"
                    className="absolute h-full w-full object-cover object-center inset-0"
                  >
                    <path d="M0 0H400L355.311 116H0V0Z" fill="black" />
                  </svg>
                  <div className="relative items-center flex gap-3">
                    <RowingIcon2 />
                    <div className="text-white text-4xl font-bold self-stretch grow whitespace-nowrap">
                      로잉머신
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex-1 overflow-y-scroll">
                <div className="max-md:max-w-full max-h-full">
                  <div className="p-5 flex gap-5 items-center border-b border-[#EEEEF6]">
                    <p className="text-lg font-bold">로잉머신</p>
                    <button
                      className={cn(
                        "py-3 px-4 text-[#BFFF00] text-sm font-bold border border-[#EEEEF6] rounded-lg",
                        edit && "gradient-bg text-white border-[unset]"
                      )}
                      onClick={() => {
                        if (edit) {
                          addRowing(pins).then(() => {
                            getData();
                          });
                        }
                        setEdit((prev) => !prev);
                      }}
                    >
                      {edit ? "저장" : "수정"}
                    </button>
                  </div>
                  <div className="p-5 flex flex-col gap-5">
                    <div className="flex flex-col gap-[6px]">
                      1
                      <input
                        className="p-3 border border-[#8D9CB6] rounded w-[600px]"
                        value={pins[0]}
                        onChange={(e) => {
                          setPins((prev) => {
                            const copy = [...prev];
                            copy[0] = e.target.value;
                            return copy;
                          });
                        }}
                        disabled={!edit}
                      />
                    </div>
                    <div className="flex flex-col gap-[6px]">
                      2
                      <input
                        className="p-3 border border-[#8D9CB6] rounded w-[600px]"
                        value={pins[1]}
                        onChange={(e) => {
                          setPins((prev) => {
                            const copy = [...prev];
                            copy[1] = e.target.value;
                            return copy;
                          });
                        }}
                        disabled={!edit}
                      />
                    </div>
                    <div className="flex flex-col gap-[6px]">
                      3
                      <input
                        className="p-3 border border-[#8D9CB6] rounded w-[600px]"
                        value={pins[2]}
                        onChange={(e) => {
                          setPins((prev) => {
                            const copy = [...prev];
                            copy[2] = e.target.value;
                            return copy;
                          });
                        }}
                        disabled={!edit}
                      />
                    </div>
                    <div className="flex flex-col gap-[6px]">
                      4
                      <input
                        className="p-3 border border-[#8D9CB6] rounded w-[600px]"
                        value={pins[3]}
                        onChange={(e) => {
                          setPins((prev) => {
                            const copy = [...prev];
                            copy[3] = e.target.value;
                            return copy;
                          });
                        }}
                        disabled={!edit}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <Modal
        open={showVideo}
        handleClose={() => {
          setShowVideo(false);
          setVideo(null);
        }}
      >
        <div className="flex items-center justify-center w-full h-full">
          <video className="w-[1000px] h-[562px]" controls autoPlay key={video}>
            <source src={video ?? ""} type="video/mp4" />
          </video>
        </div>
      </Modal>
    </div>
  );
}

export default Admin;
