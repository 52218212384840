import React, { useEffect, useRef, useState } from "react";
import constate from "constate";
import authApi from "../client-api/auth";
import { useTimer } from "react-use-precision-timer";
import { CircuitProgram, WeightProgram } from "../types";
import { getProgram } from "../client-api/program";
import dayjs from "dayjs";
import EventEmitter from "eventemitter3";
import { getRowing } from "../client-api/rowing";

const preloadVideoList: {
  video: string;
  url: string;
}[] = [];
async function preloadVideo(src: string) {
  const res = await fetch(src);
  const blob = await res.blob();
  return URL.createObjectURL(blob);
}

function useAuth() {
  const emitter = useRef<EventEmitter>(new EventEmitter());
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [did, setDid] = useState<"0" | "L1" | "L6" | "R1" | "R6" | "1">();
  const [row, setRow] = useState<"300m" | "600m" | "1200m">();
  const [rowProgressData, setRowProgressData] = useState<{
    L1: number;
    L6: number;
    R1: number;
    R6: number;
  }>({
    L1: 0,
    L6: 0,
    R1: 0,
    R6: 0,
  });
  const firstProgressData = useRef<{
    L1: number;
    L6: number;
    R1: number;
    R6: number;
  }>({
    L1: 0,
    L6: 0,
    R1: 0,
    R6: 0,
  });

  const [rowConnected, setRowConnected] = useState<{
    L1: boolean;
    L6: boolean;
    R1: boolean;
    R6: boolean;
  }>({
    L1: false,
    L6: false,
    R1: false,
    R6: false,
  });
  const socket = useRef<WebSocket>(
    new WebSocket(process.env.REACT_APP_WEB_SOCKET_URL + "/websocket")
  );
  const rowerSocket = useRef<WebSocket>();

  const [step, setStep] = useState(0);
  const [weightProgram, setWeightProgram] = useState<WeightProgram>();
  const [circuitProgram, setCircuitProgram] = useState<CircuitProgram>();
  const startTime = useRef<number>(0);

  const timer = useTimer({
    fireOnStart: false,
    startImmediately: false,
  });

  const sendRowDisconnect = () => {
    rowerSocket.current?.send(
      JSON.stringify({
        type: "disconnect",
      })
    );
  };

  useEffect(() => {
    if (step === 0 && (did === "L1" || did === "R1") && rowerSocket.current) {
      if (window.electronAPI) {
        window.electronAPI.closeExe();
      }
      rowerSocket.current.close();
      rowerSocket.current = undefined;
    }
  }, [did, step]);

  const connectRowSocket = () => {
    if (rowerSocket.current) {
      rowerSocket.current.addEventListener("close", (event) => {
        console.log("rowerSocket close");
      });
      rowerSocket.current.addEventListener("message", (event) => {
        try {
          const data = JSON.parse(event.data);
          switch (data.type) {
            case "connected":
              sendMessage(
                JSON.stringify({
                  type: "ROWER_CONNECTED",
                  did: did,
                })
              );
              break;
            case "error":
              alert("머신 연결에 실패했습니다. " + data.message);
              break;
            case "distance":
              let bleDid = "";
              if (did === "L1" && data.client === 0) {
                bleDid = "L1";
              } else if (did === "L1" && data.client === 1) {
                bleDid = "L6";
              } else if (did === "R1" && data.client === 0) {
                bleDid = "R1";
              } else if (did === "R1" && data.client === 1) {
                bleDid = "R6";
              }
              sendMessage(
                JSON.stringify({
                  type: "DISTANCE",
                  distance: data.distance,
                  did: bleDid,
                })
              );
              break;
          }
        } catch (e) {
          alert("rowerSocket message error" + e + typeof e);
          console.log(e);
        }
      });
    }
  };

  useEffect(() => {
    const connectSocket = () => {
      console.log("실행됨");
      socket.current.addEventListener("open", (event) => {
        if (localStorage.getItem("token")) {
          socket.current.send(
            JSON.stringify({
              type: "CREATE_SESSION",
              data: localStorage.getItem("token"),
            })
          );
        }
      });
      socket.current.addEventListener("message", (event) => {
        try {
          const data = JSON.parse(JSON.parse(event.data));
          console.log("message", data);
          if (data.type === "START_PROGRAM") {
            startTime.current = 0;
            setLoading(true);
            setStep(0);
            setWeightProgram(undefined);
            setCircuitProgram(undefined);
            setRow(data.rowType);
            setRowProgressData({
              L1: 0,
              L6: 0,
              R1: 0,
              R6: 0,
            });
            firstProgressData.current = {
              L1: 0,
              L6: 0,
              R1: 0,
              R6: 0,
            };
            setRowConnected({
              L1: false,
              L6: false,
              R1: false,
              R6: false,
            });
            getProgram(data.programId).then(async (program) => {
              if (program.programType === "WEIGHT") {
                const weightProgram = program as WeightProgram;
                const videoList = [
                  ...weightProgram.coolDownMovements.map(
                    (movement) => movement.movement.video
                  ),
                  ...weightProgram.warmUpMovements.map(
                    (movement) => movement.video
                  ),
                  ...weightProgram.firstHalfMovements.map(
                    (movement) => movement.movement.video
                  ),
                  ...weightProgram.secondHalfMovements.map(
                    (movement) => movement.movement.video
                  ),
                ];

                const newVideoList = videoList.reduce(
                  (acc, cur) =>
                    acc.includes(cur) ||
                    preloadVideoList.find((video) => video.video === cur)
                      ? acc
                      : [...acc, cur],
                  [] as string[]
                );

                const _preloadVideoList = await Promise.all(
                  newVideoList.map(async (video) => {
                    const url = await preloadVideo(video);
                    return { video, url };
                  })
                );
                preloadVideoList.push(..._preloadVideoList);
                weightProgram.coolDownMovements.map(
                  (movement) =>
                    (movement.movement.video = preloadVideoList.find(
                      (video) => video.video === movement.movement.video
                    )!.url)
                );
                weightProgram.warmUpMovements.map(
                  (movement) =>
                    (movement.video = preloadVideoList.find(
                      (video) => video.video === movement.video
                    )!.url)
                );
                weightProgram.firstHalfMovements.map(
                  (movement) =>
                    (movement.movement.video = preloadVideoList.find(
                      (video) => video.video === movement.movement.video
                    )!.url)
                );
                weightProgram.secondHalfMovements.map(
                  (movement) =>
                    (movement.movement.video = preloadVideoList.find(
                      (video) => video.video === movement.movement.video
                    )!.url)
                );

                setLoading(false);
                setWeightProgram(weightProgram);
              }
              if (
                program.programType === "CIRCUIT" ||
                program.programType === "BOOSTER"
              ) {
                const circuitProgram = program as CircuitProgram;
                let videoList = [
                  ...circuitProgram.coolDownMovements.map(
                    (movement) => movement.movement.video
                  ),
                  ...circuitProgram.warmUpMovements.map(
                    (movement) => movement.video
                  ),
                  ...circuitProgram.areaAMovements.map(
                    (movement) => movement.video
                  ),
                  ...circuitProgram.areaBMovements.map(
                    (movement) => movement.video
                  ),
                ];

                const newVideoList = videoList.reduce(
                  (acc, cur) =>
                    acc.includes(cur) ||
                    preloadVideoList.find((video) => video.video === cur)
                      ? acc
                      : [...acc, cur],
                  [] as string[]
                );

                const _preloadVideoList = await Promise.all(
                  newVideoList.map(async (video) => {
                    const url = await preloadVideo(video);
                    return { video, url };
                  })
                );
                preloadVideoList.push(..._preloadVideoList);

                circuitProgram.coolDownMovements.map(
                  (movement) =>
                    (movement.movement.video = preloadVideoList.find(
                      (video) => video.video === movement.movement.video
                    )!.url)
                );
                circuitProgram.warmUpMovements.map(
                  (movement) =>
                    (movement.video = preloadVideoList.find(
                      (video) => video.video === movement.video
                    )!.url)
                );
                circuitProgram.areaAMovements.map(
                  (movement) =>
                    (movement.video = preloadVideoList.find(
                      (video) => video.video === movement.video
                    )!.url)
                );
                circuitProgram.areaBMovements.map(
                  (movement) =>
                    (movement.video = preloadVideoList.find(
                      (video) => video.video === movement.video
                    )!.url)
                );

                setLoading(false);
                setCircuitProgram(circuitProgram);
              }
              setStep(1);
            });
          }
          if (data.type === "START_TIMER") {
            const time = data.startTime;
            timer.start();
            setStep(2);
          }
          if (data.type === "SET_STEP") {
            const time = data.startTime;
            setStep(data.step);
            startTime.current = time;
          }
          if (data.type === "START_COUNTDOWN") {
            emitter.current.emit("resume", data.startTime);
          }
          if (data.type === "STOP_COUNTDOWN") {
            emitter.current.emit("pause", data.startTime);
          }
          if (data.type === "DISTANCE") {
            const did: "L1" | "L6" | "R1" | "R6" = data.did;
            let distance = data.distance;
            if (firstProgressData.current[did] === 0) {
              firstProgressData.current[did] = data.distance;
            }
            distance = data.distance - firstProgressData.current[did];
            setRowProgressData((prev) => ({
              ...prev,
              [data.did]: distance,
            }));
          }
          if (data.type === "ROWER_CONNECTED") {
            if (data.did === "L1") {
              setRowConnected((prev) => ({
                ...prev,
                L1: data.did === "L1",
                L6: data.did === "L1",
              }));
            } else if (data.did === "R1") {
              setRowConnected((prev) => ({
                ...prev,
                R1: data.did === "R1",
                R6: data.did === "R1",
              }));
            }
          }
        } catch (e) {
          console.log(e);
        }
      });
      socket.current.addEventListener("close", (event) => {
        // retry
        socket.current.close();
        setTimeout(() => {
          socket.current = new WebSocket(
            process.env.REACT_APP_WEB_SOCKET_URL + "/websocket"
          );
          connectSocket();
        }, 300);
        console.log("close");
      });
    };
    connectSocket();
    return () => {
      // socket.current.close();
      // emitter.current.removeAllListeners();
    };
  }, []);

  const login = async (username: string, password: string, did: string) => {
    if (
      did !== "0" &&
      did !== "L1" &&
      did !== "L6" &&
      did !== "R1" &&
      did !== "R6" &&
      did !== "1"
    ) {
      alert("DID를 확인해주세요.");
      return;
    }
    try {
      const data = await authApi.login(username, password, did);
      localStorage.setItem("token", data.tokens.accessToken);
      localStorage.setItem("refreshToken", data.tokens.refreshToken);
      localStorage.setItem("did", did);
      setDid(did as any);
      setIsAuthenticated(true);
      setStep(0);
      setWeightProgram(undefined);
      setCircuitProgram(undefined);
      setRow(undefined);
      setRowProgressData({
        L1: 0,
        L6: 0,
        R1: 0,
        R6: 0,
      });
      firstProgressData.current = {
        L1: 0,
        L6: 0,
        R1: 0,
        R6: 0,
      };
      setRowConnected({
        L1: false,
        L6: false,
        R1: false,
        R6: false,
      });
      socket.current.send(
        JSON.stringify({
          type: "CREATE_SESSION",
          data: data.tokens.accessToken,
        })
      );
    } catch (e) {
      alert("아이디와 비밀번호를 확인해주세요.");
    }
  };

  const logout = async () => {
    await authApi.logout();
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("did");
    setIsAuthenticated(false);
  };

  const refreshToken = async () => {
    // const data = await authApi.refreshToken();
    // localStorage.setItem("token", data.accessToken);
    // localStorage.setItem("refreshToken", data.refreshToken);
    setIsAuthenticated(true);
    setLoading(false);
    setDid(localStorage.getItem("did") as any);
    socket.current.send(
      JSON.stringify({
        type: "CREATE_SESSION",
        data: localStorage.getItem("token"),
      })
    );
  };

  useEffect(() => {
    if (localStorage.getItem("token")) {
      (async () => {
        try {
          await refreshToken();
        } catch {
          setLoading(false);
        }
      })();
    } else {
      setLoading(false);
    }
  }, []);

  const sendMessage = (message: string) => {
    socket.current.send(
      JSON.stringify({
        type: "SEND_MESSAGE",
        data: message,
      })
    );
  };
  const startProgram = (
    programId: number,
    rowType: "300m" | "600m" | "1200m" | undefined
  ) => {
    sendMessage(
      JSON.stringify({
        type: "START_PROGRAM",
        programId: programId,
        rowType: rowType,
      })
    );
  };
  const sendStartTimer = () => {
    sendMessage(
      JSON.stringify({
        type: "START_TIMER",
        startTime: dayjs().add(200, "ms").valueOf(),
      })
    );
  };

  const sendSetStep = (step: number) => {
    sendMessage(
      JSON.stringify({
        type: "SET_STEP",
        step: step,
        startTime: dayjs().add(200, "ms").valueOf(),
      })
    );
  };

  const sendStartCountDown = () => {
    sendMessage(
      JSON.stringify({
        type: "START_COUNTDOWN",
        startTime: dayjs().add(200, "ms").valueOf(),
      })
    );
  };

  const sendStopCountDown = () => {
    sendMessage(
      JSON.stringify({
        type: "STOP_COUNTDOWN",
        startTime: dayjs().add(200, "ms").valueOf(),
      })
    );
  };

  const sendRowConnect = async () => {
    if (window.electronAPI && (did === "L1" || did === "R1") && row) {
      window.electronAPI.openExe();
    }
    let opend = false;
    const openRowSocket = async () => {
      opend = true;
      console.log("rowerSocket open");
      const data = await getRowing();
      rowerSocket.current?.send(
        JSON.stringify({
          type: "connect",
          pins:
            did === "L1"
              ? data.rowingMachineIds.slice(0, 2)
              : data.rowingMachineIds.slice(2, 4),
        })
      );
    };
    if (did === "L1" || did === "R1") {
      await new Promise((resolve) => {
        const interval = setInterval(() => {
          if (opend) {
            clearInterval(interval);
            resolve(undefined);
          } else {
            rowerSocket.current?.removeEventListener("open", openRowSocket);
            rowerSocket.current?.close();
            rowerSocket.current = undefined;
            rowerSocket.current = new WebSocket("ws://localhost:8765");
            connectRowSocket();
            rowerSocket.current?.addEventListener("open", openRowSocket);
          }
        }, 1000);
      });
    }
  };

  return {
    isAuthenticated,
    login,
    logout,
    refreshToken,
    loading,
    did,
    socket,
    step,
    setStep,
    timer,
    weightProgram,
    circuitProgram,
    startProgram,
    sendStartTimer,
    sendSetStep,
    startTime,
    emitter,
    sendStartCountDown,
    sendStopCountDown,
    row,
    rowConnected,
    rowProgressData,
    sendRowConnect,
    sendRowDisconnect,
  };
}

// 2️⃣ Wrap your hook with the constate factory
const [AuthProvider, useAuthContext] = constate(useAuth);

export { AuthProvider, useAuthContext };
